import React, { useState, useEffect, useRef } from "react";
import { graphql, Link } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import BEMHelper from "react-bem-helper";
import SwiftType from "../components/swift-type";
import AddToCalendar from "react-add-to-calendar";
// Components
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeaderImageVideo from "../modules/headers/header-image-video";
import HeaderSolidColor from "../modules/headers/header-solid-color";

// Helpers, Functions
import { richTextConfig } from "../rich-text-config/rich-text-config";

import LinkedinLogo from "../blocks/linkedin-logo";
import EmailLogo from "../blocks/email-logo";
import TwitterLogo from "../blocks/twitter-logo";
import FacebookLogo from "../blocks/facebook-logo";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const bem = new BEMHelper({
  name: "template-event",
});

const TemplateEvent = ({ data, location, pageContext }) => {
  const [selectedInfo, setSelectedInfo] = useState("overview");
  const [addToCalendar, setAddToCalendar] = useState(false);
  const [shareUrl, setShareUrl] = useState(null);

  const eventData = data.allContentfulTemplateEvent.edges[0].node;

  const calRef = useRef(null);
  const {
    addressPlainText,
    allDay,
    startDateTime,
    swiftDate,
    endDateTime,
    eventPhoto,
    eventTitle,
    seoPageTitle,
    slug,
    eventBody,
    eventHeaderDescription,
    eventRegistrationLink,
  } = eventData;

  useEffect(() => {
    // document.getElementById("loader_wrap").classList.add("d-none");
    // document.getElementById("loader_wrap").classList.add("loaded");

    // console.log("Cal ref: ", calRef.current);

    setAddToCalendar(true);

    setShareUrl(window.location.href.split("#")[0]);
  }, []);

  const Loader = () => {
    let loaderDiv = document.getElementById("loader");
    let delay = 0.004;

    for (let x = 0; x < 40; x++) {
      let width = x * 2.5 + "%";
      let div = document.createElement("div");
      loaderDiv.appendChild(div);
      div.style.left = width;
      div.style.top = 0;
      div.style.animationDelay = ((x - 20) * (x - 20) * delay) / 8 + "s";
    }
  };

  const event = {
    title: eventTitle,
    description: eventHeaderDescription.eventHeaderDescription,
    location: addressPlainText.addressPlainText,
    startTime: startDateTime,
    endTime: endDateTime,
  };
  // console.log(event);
  const getFormatDate = (val) => {
    return new Intl.DateTimeFormat("en-US", {
      month: "long",
      year: "numeric",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZoneName: "short",
    }).format(new Date(val));
  };

  const listItems = [
    { outlook: "Outlook" },
    { google: "Google Calendar" },
    { apple: "iCal" },
  ];

  const metaTitle = seoPageTitle || `${eventTitle} | Events | UnitedLex`;
  return (
    <Layout location={location} additionalClassName={slug}>
      <SEO
        title={metaTitle}
        description={eventHeaderDescription?.eventHeaderDescription}
        url={location?.href}
        image={eventPhoto?.file?.url}
      />
      <SwiftType
        customTitle={eventTitle}
        customDesc={eventHeaderDescription?.eventHeaderDescription}
        customPublishDate={new Date(swiftDate).getTime() / 1000}
        customFeatImg={eventPhoto?.file?.url}
        contentTypes={["event"]}
        tags={["event"]}
      />
      <div {...bem()}>
        <div {...bem("body")}>
          <div {...bem("header")}>
            <div>
              <p>Event</p>
              <h1>{eventTitle || "Title goes here"}</h1>
              <p>
                {eventHeaderDescription?.eventHeaderDescription ||
                  "Description goes here"}
              </p>
              {eventRegistrationLink && (
                <a
                  href={eventRegistrationLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Register Now
                </a>
              )}

              {/* <button>Register Now</button> */}
            </div>
            <div {...bem("header-img")}>
              {eventPhoto && <GatsbyImage image={getImage(eventPhoto.gatsbyImageData)} alt={eventPhoto.description || ""} title={eventPhoto.description || ""} />}
            </div>
          </div>
          <div {...bem("content-tabs")}>
            <ul>
              <li
                onClick={() => setSelectedInfo("overview")}
                className={selectedInfo === "overview" ? "highlighted" : ""}
              >
                Overview
              </li>

              {/* <li
                onClick={() => setSelectedInfo("featured-speakers")}
                className={
                  selectedInfo === "featured-speakers" ? "highlighted" : ""
                }
              >
                Featured Speakers
              </li>
              <li
                onClick={() => setSelectedInfo("program-schedule")}
                className={
                  selectedInfo === "program-schedule" ? "highlighted" : ""
                }
              >
                Program Schedule
              </li>
              <li
                onClick={() => setSelectedInfo("sponsors")}
                className={selectedInfo === "sponsors" ? "highlighted" : ""}
              >
                Sponsors
              </li> */}
            </ul>
          </div>
          <div {...bem("content-wrap")}>
            <div>
              <div>
                <h1>Date and Time</h1>
                <p>
                  <b>Start:</b>{" "}
                  {getFormatDate(startDateTime) || "Start time goes here"}
                </p>
                <p>
                  <b>End:</b>{" "}
                  {getFormatDate(endDateTime) || "End Time Goes here"}
                </p>
                {/* <button>Add to calendar</button> */}

                {/* <button
                  {...bem("add-to-calendar")}
                  onClick={(e) => {
                    e.stopPropagation();
                    // document.querySelector(".outlook-link").click();
                  }}
                > */}
                <AddToCalendar
                  // ref={calRef}
                  event={event}
                  listItems={listItems}
                  optionsOpen={false}
                  buttonClassClosed="react-add-to-calendar__button ani-btn"
                />
                {/* {addToCalendar ? (
                  <button
                    {...bem("add-to-calendar")}
                    onClick={(e) => {
                      // e.stopPropagation();
                      document.querySelector(".outlook-link").click();
                    }}
                  >
                    Add to Calendar
                  </button>
                ) : (
                    <button
                      {...bem("add-to-calendar")}
                      onClick={(e) => {
                        // e.stopPropagation();
                        document.querySelector(".outlook-link").click();
                      }}
                    >
                      Add to Calendar
                    </button>
                  )} */}

                {/* </button> */}

                <h1 style={{ marginTop: "48px" }}>Location</h1>
                <p {...bem("address")}>{addressPlainText?.addressPlainText}</p>
              </div>
              <div {...bem("share-event-section")}>
                <p>Share this UnitedLex Event</p>
                <div {...bem("share-icon-flex")}>
                  <a
                    {...bem("share-link")}
                    target="_blank"
                    // tabIndex="0"
                    href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(
                      shareUrl
                    )}`}
                    rel="noopener noreferrer"
                    aria-label={`Share this on Facebook`}
                  >
                    <FacebookLogo />
                  </a>
                  <a
                    {...bem("share-link")}
                    target="_blank"
                    tabIndex="0"
                    href={`https://twitter.com/intent/tweet?text=${
                      eventTitle ? encodeURIComponent(eventTitle) : "UnitedLex"
                    }%0A&url=${encodeURIComponent(shareUrl)}&via=UnitedLex`}
                    rel="noopener noreferrer"
                    aria-label={`Share this on Twitter`}
                  >
                    <TwitterLogo />
                  </a>
                  <a
                    {...bem("share-link")}
                    target="_blank"
                    tabIndex="0"
                    href={`https://www.linkedin.com/shareArticle?mini=true&title=${
                      eventTitle
                        ? encodeURIComponent(eventTitle)
                        : encodeURIComponent("UnitedLex")
                    }&url=${encodeURIComponent(shareUrl)}&source=UnitedLex`}
                    rel="noopener noreferrer"
                    aria-label={`Share this on Linkedin`}
                  >
                    <LinkedinLogo />
                  </a>

                  <a
                    {...bem("share-link")}
                    tabIndex="0"
                    target="_blank"
                    href={`mailto:?subject=${
                      eventTitle
                        ? encodeURIComponent(eventTitle)
                        : encodeURIComponent("UnitedLex")
                    }&body=Read%20this%20insight%20from%20UnitedLex:%20%0A%0A${
                      eventTitle
                        ? encodeURIComponent(eventTitle)
                        : encodeURIComponent("UnitedLex")
                    }%0A${encodeURIComponent(shareUrl)}`}
                    rel="noopener noreferrer"
                    aria-label={`Share this via Email`}
                  >
                    <EmailLogo />
                  </a>
                </div>
              </div>
            </div>
            <article {...bem("event-content")}>
              <div {...bem("body-copy-wrapper")}>
                {documentToReactComponents(JSON.parse(eventBody.raw), richTextConfig())}
              </div>
            </article>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TemplateEvent;

export const pageQuery = graphql`
  query($slug: String!) {
    allContentfulTemplateEvent(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          addressPlainText {
            addressPlainText
          }
          swiftDate: startDateTime(formatString: "YYYY.MM.DD")
          eventRegistrationLink
          allDay
          endDateTime
          eventTitle
          seoPageTitle
          slug
          doNotIndex
          startDateTime
          eventPhoto {
            description
            gatsbyImageData
            # file {
            #   url
            # }
            # fluid(
            #   maxWidth: 800
            #   toFormat: JPG
            #   cropFocus: FACES
            #   resizingBehavior: FILL
            # ) {
            #   ...GatsbyContentfulFluid
            # }
          }
          eventBody {
            raw
          }
          eventHeaderDescription {
            eventHeaderDescription
          }
        }
      }
    }
  }
`;
